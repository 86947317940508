// core version + navigation, pagination modules:
import Swiper from './swiper.js';

window.addEventListener('load', () => {

    const swiperElements = document.querySelectorAll('.custom__swiper');

    let counter = 0;
    swiperElements.forEach((swiperEl) => {

        swiperEl.classList.add(`swiper__${counter}`);

        const slidesPerView = swiperEl.dataset.slides;
        const pagination = swiperEl.dataset.pagination;
        const navigation = swiperEl.dataset.navigation;
        const space = swiperEl.dataset.space;
        const swiperName = `.swiper__${counter}`;
        const autoHeight = swiperEl.dataset.autoHeight;
        const autoplay = swiperEl.dataset.autoPlay;

        let autoplayBool = false;
        if (autoplay === 'true') {
            autoplayBool = true
        }

        let autoHeightBool = false;
        if (autoHeight === 'true') {
            autoHeightBool = true
        }

        let navigationObj = {};
        if (navigation === 'true') {
            navigationObj = {
                nextEl: `.swiper-button-next-${counter}`,
                prevEl: `.swiper-button-prev-${counter}`,
            }

            const navigationElPrev = document.createElement('div');
            navigationElPrev.classList.add('swiper-button-prev');
            navigationElPrev.classList.add(`swiper-button-prev-${counter}`);

            const navigationElNext = document.createElement('div');
            navigationElNext.classList.add('swiper-button-next');
            navigationElNext.classList.add(`swiper-button-next-${counter}`);

            swiperEl.parentNode.insertBefore(navigationElPrev, swiperEl.nextSibling);
            swiperEl.parentNode.insertBefore(navigationElNext, swiperEl.nextSibling);
        }

        let paginationObj = {};
        if (pagination === 'true') {
            paginationObj = {
                el: `.swiper-pagination-${counter}`,
                clickable: true,
            }

            const paginationEl = document.createElement('div');
            paginationEl.classList.add(`swiper-pagination-${counter}`);
            paginationEl.classList.add('swiper-pagination-all');
            swiperEl.parentNode.insertBefore(paginationEl, swiperEl.nextSibling);
        }

        let slides600 = 2;
        let spaceSmall = 10;
        if (slidesPerView === "1") {
            slides600 = 1
            spaceSmall = parseInt(space)
        }

        let slides900 = 3;
        if (slidesPerView === "1") {
            slides900 = 1
        }

        const swiper = new Swiper(swiperName, {
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            speed: 800,
            parallax: true,
            spaceBetween: parseInt(space),

            // Navigation arrows
            navigation: navigationObj,
            pagination: paginationObj,
            slidesPerView: slidesPerView,
            autoHeight: autoHeightBool,
            autoplay: autoplayBool,

            breakpoints: {
                120: {
                    slidesPerView: 1,
                    spaceBetween: spaceSmall
                },

                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: spaceSmall
                },
                // when window width is >= 640px
                600: {
                    slidesPerView: slides600,
                    spaceBetween: parseInt(space)
                },

                900: {
                    slidesPerView: slides900,
                    spaceBetween: parseInt(space)
                },

                1200: {
                    slidesPerView: slidesPerView,
                    spaceBetween: parseInt(space)
                }

            }

        });

        counter++;
    })


}, false);

document.addEventListener('DOMContentLoaded', () => {

    /** parallax instant */
    let parallaxInstantTop = document.querySelectorAll('.parallax-instant--top');
    let parallaxInstantBottom = document.querySelectorAll('.parallax-instant--bottom');

    activateParallax(parallaxInstantTop, 'instantTop');
    activateParallax(parallaxInstantBottom, 'instantBottom');

    const svgElements = document.querySelectorAll('.svg__animation');

    /** parallax */
    const parallaxLefts = document.querySelectorAll('.parallax-left');
    const parallaxRights = document.querySelectorAll('.parallax-right');
    const parallaxBottoms = document.querySelectorAll('.parallax-bottom');
    const parallaxTops = document.querySelectorAll('.parallax-top');
    const parallaxBackgrounds = document.querySelectorAll('.parallax-background');

    const header = document.querySelectorAll('header');

    activateParallax(parallaxLefts, 'left');
    activateParallax(parallaxRights, 'right');
    activateParallax(parallaxBottoms, 'bottom');
    activateParallax(parallaxTops, 'top');
    activateParallax(parallaxBackgrounds, 'background');

    /** content-menu-with content */

    window.addEventListener('scroll', () => {

        svgAnimation(svgElements);

        activateParallax(parallaxLefts, 'left');
        activateParallax(parallaxRights, 'right');
        activateParallax(parallaxBottoms, 'bottom');
        activateParallax(parallaxTops, 'top');
        activateParallax(parallaxBackgrounds, 'background');

        /** header */
        stickyHeader(header);

    })

    let burgerMenu = document.querySelector('.hamburger');
    let navMobile = document.querySelector('.nav-mobile');
    let headerItems = document.querySelectorAll('.header__item--mobile');
    let headerMobile = document.querySelector('.header-mobile');

    if (isNotNull(burgerMenu) && isNotNull(navMobile)) {
        burgerMenu.addEventListener('click', () => {
            burgerMenu.classList.toggle('is-active');
            navMobile.classList.toggle('nav-mobile-active');
            headerMobile.classList.toggle('header-mobile--active');

            setTimeout(() => {
                headerItems.forEach((el, index) => {
                        if (el.style.animation) {
                            el.style.animation = '';
                        } else {
                            el.style.animation = `nav-link-fade 0.5s ease forwards ${index / 7 + 0.3}s`
                        }
                })
            }, 200)


        })
    }

    jobAction();

    hotspotAction();

    cookieAction();

})

const hotspotAction = () => {

    const hotspotElements = document.querySelectorAll('.hotspot__svg');
    const hotspotElementsMobile = document.querySelectorAll('.hotspot__content--mobile');

    if (isNotNull(hotspotElements) && isNotNull(hotspotElementsMobile)) {
        hotspotElements.forEach((hotspot) => {
            let target = hotspot.dataset.target;

            hotspot.addEventListener('click', () => {
                hotspotElementsMobile.forEach((el) => {
                    el.classList.remove('hotspot__content--mobile--active');
                })

                hotspotElementsMobile[target].classList.add('hotspot__content--mobile--active');
            })
        })
    }
}


const jobAction = () => {

    const jobElements = document.querySelectorAll('.job__action');

    if (isNotNull(jobElements)) {
        jobElements.forEach((job) => {
            job.addEventListener('click', () => {
                job.classList.toggle('job__action--expanded');
                job.classList.toggle('job__action--unexpanded');

                const description = job.parentElement.querySelector('.job__description');
                description.classList.toggle('job__description--inactive');

                const arrow = job.querySelector('.job__arrow');
                arrow.classList.toggle('job__arrow--right');
                arrow.classList.toggle('job__arrow--top');

                const textAction = job.querySelector('.job__text-action')

                if (arrow.classList.contains('job__arrow--top')) {
                    textAction.textContent = 'Weniger anzeigen';
                } else {
                    textAction.textContent = 'Mehr anzeigen';
                }
            })
        })
    }

}

const stickyHeader = (header) => {

    if (isNotNull(header)) {
        header.forEach((element)  => {
            let position = element.offsetTop;
            if (window.pageYOffset > position) {
                element.classList.add("sticky");
            } else {
                element.classList.remove("sticky");
            }
        })
    }
}

const svgAnimation = (elements) => {

    elements.forEach((element) => {

        if (isNotNull(element)) {
            let distanceTop = element.getBoundingClientRect().top;
            if (distanceTop > innerHeight - 50) {
                element.style.height = '0px';
            } else {
                const realHeight = element.dataset.height;
                const height = innerHeight - 50 - distanceTop;

                if (height > parseInt(realHeight)) {
                    element.style.height = `${realHeight}px`;
                } else {
                    element.style.height = `${height}px`;
                }
            }
        }
    });

}

const activateParallax = (elements, direction) => {

    if (isNotNull(elements)) {
        for (let el of elements) {
            let distanceTop = el.getBoundingClientRect().top;

            if (direction === "background") {

                let movement = el.dataset.move;

                if (movement === "down") {
                    if (distanceTop < innerHeight) {
                        let height = (innerHeight - distanceTop)/8;
                        height = Math.floor(height);
                        el.style.top = `${height}px`
                    }
                } else if (movement === "up") {
                    if (distanceTop < innerHeight) {
                        let height = (innerHeight - distanceTop)/8;
                        height = Math.floor(height);
                        el.style.bottom = `${height}px`
                    }
                }


            } else {

                if (distanceTop < innerHeight) {
                    el.style.transform = 'translate3d(0px, 0px, 0px)';
                    el.style.opacity = '1';
                }

                if (distanceTop > innerHeight-100) {
                    if (direction === 'left') {
                        //el.style.transform = 'translate3d(-' + el.getBoundingClientRect().top/10 +'px, 0px, 0px)';
                        el.style.transform = 'translate3d(-100px, 0px, 0px)';
                    } else if(direction === 'right') {
                        //el.style.transform = 'translate3d(' + el.getBoundingClientRect().top/10 +'px, 0px, 0px)';
                        el.style.transform = 'translate3d(100px, 0px, 0px)';
                    } else if(direction === 'top') {
                        el.style.transform = 'translate3d( 0px, 100px, 0px)';
                    } else if(direction === 'instantTop') {
                        el.style.transform = 'translate3d( 0px, 100px, 0px)';
                    } else if(direction === 'instantBottom') {
                        el.style.transform = 'translate3d( 0px, 100px, 0px)';
                    } else {
                        el.style.transform = 'translate3d( 0px, 100px, 0px)';
                    }
                    el.style.opacity = '0';
                }
            }

        }
    }
}

const isNotNull = (element) => {
    return typeof element !== 'undefined' && element !== null;
}

const scroll = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

const cookieAction = () => {
    const cookieName = "berchtenbreiter-cookie";
    const cookie = getCookie(cookieName);
    if (!isNotNull(cookie)) {
        const cookieBanner = document.querySelector('.cookie');
        if (isNotNull(cookieBanner)) {
            cookieBanner.classList.add('cookie--active');
            const cookieAccept = document.querySelector('.cookie__accept');
            if (isNotNull(cookieAccept)) {
                cookieAccept.addEventListener('click', () => {
                    cookieBanner.classList.remove('cookie--active');
                    setCookie(cookieName, "true", 5);
                })
            }
        }
    }
}

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

